import React from "react";

function Layer1(){
    return (

        <div>
            <div>
                <h1> About Me ☺&#xFE0E;</h1>
                <p>I recently graduated from Columbia University, where I studied computer science and English.</p>
                I care about <ul>
                <li>design-driven engineering</li>
                <li>user experience</li>
                <li>product & brand identity</li>
                <li>contemporary art & design</li>

            </ul>
                I like <ul>
                <li>figuring out how my favorite software products work</li>
                <li>spending lots of time in Figma</li>
                <li>designing typefaces</li>
                <li>reading short stories</li>
                <li>hand embroidery, oil painting, and graphite drawing</li>
            </ul>
            </div>
        </div>
    );

}

export default Layer1